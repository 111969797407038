import React, { useState } from "react"
import { graphql } from "gatsby"
import ArrowLeft from "../images/arrowLeft.js"
import ArrowRight from "../images/arrowRight.js"
import "../styles/main.css"
import "./styles.css"

function ProjectTemplate(props) {
    let [galleryState, setGalleryState] = useState("before");
    let [beforeIndex, setBeforeIndex] = useState(0);
    let [afterIndex, setAfterIndex] = useState(0);
    let [beforeElemsInView, setBeforeElemsInView] = useState([0, 1, 2]);
    let [afterElemsInView, setAfterElemsInView] = useState([0, 1, 2]);

    return (
        <div className="project">
            <div className="projectIn">

                <div className="projectRight">
                    <a href="../../projects" className="projectBackBtn"><ArrowLeft color="#20265E" />Edellinen</a>
                    <div className="projectTitleRow">
                        <div className="projectTitle">{props.title}</div>
                        <div className="projectButtons">
                            <div onClick={() => setGalleryState("before")} className={galleryState === "before" ? "projectButton projectButton__active" : "projectButton"}>
                                Ennen
                                <div className="projectButtonUnderline"></div>
                            </div>
                            <div className="projectButtonsSeparator"></div>
                            <div onClick={() => setGalleryState("after")} className={galleryState === "after" ? "projectButton projectButton__active" : "projectButton"}>
                                Jälkeen
                                <div className="projectButtonUnderline"></div>
                            </div>
                        </div>
                    </div>
                    <div className="projectCurrentImage">
                        <div className="projectResponsiveArrow projectResponsiveArrow__left"onClick={() => {
                            if (galleryState === "before") updateBeforeIndex(beforeIndex - 1);
                            else if (galleryState === "after") updateBeforeIndex(afterIndex - 1);
                        }}><ArrowLeft color="#20265E" /></div>
                        {renderCurrentImage()}
                        <div className="projectResponsiveArrow projectResponsiveArrow__right" onClick={() => {
                            if (galleryState === "before") updateBeforeIndex(beforeIndex + 1);
                            else if (galleryState === "after") updateBeforeIndex(afterIndex + 1);
                        }}><ArrowRight color="#20265E" /></div>
                    </div>
                    <div className="projectText">{props.text}</div>
                </div>

                <div className="projectLeft">
                    <div className="projectLeftIn">
                        <div className="arrowTop" onClick={() => {
                            if (galleryState === "before") updateBeforeIndex(beforeIndex - 1);
                            else if (galleryState === "after") updateBeforeIndex(afterIndex - 1);
                        }}></div>
                        {renderGalleries()}
                        <div className="arrowBottom" onClick={() => {
                            if (galleryState === "before") updateBeforeIndex(beforeIndex + 1);
                            else if (galleryState === "after") updateBeforeIndex(afterIndex + 1);
                        }}></div>
                    </div>
                </div>
            </div>
        </div>
    )

    function renderGalleries() {
        let extractedBefore = props.before.slice(beforeElemsInView[0], beforeElemsInView[2] + 1);
        let extractedAfter = props.after.slice(afterElemsInView[0], afterElemsInView[2] + 1);

        return (
            <div className="projectGalleries">
                <div className={galleryState === "before" ? "projectGallery projectGalleryBefore" : "projectGallery projectGalleryBefore dn"}>
                    {renderGallery(extractedBefore, "before")}
                </div>
                <div className={galleryState === "after" ? "projectGallery projectGalleryAfter" : "projectGallery projectGalleryAfter dn"}>
                    {renderGallery(extractedAfter, "after")}
                </div>
            </div>
        )
    }

    function renderGallery(gallery, type) {
        if (type === "before") {
            return gallery.map((item, i) => {
                return (
                    <div key={i} className="projectGalleryImageWrap" onClick={() => {
                            updateBeforeIndex(beforeElemsInView[i]);
                        }} >
                        <img src={item.image} className="projectGalleryImage"alt="" />
                        <div className={beforeElemsInView[i] === beforeIndex ? "projectGalleryImageLine" : "dn"}></div>
                        <div className="projectsGalleryImageHover"></div>
                    </div>
                )
            })
        }
        else if (type === "after") {
            return gallery.map((item, i) => {
                return (
                    <div key={i} className="projectGalleryImageWrap" onClick={() => {
                        updateAfterIndex(afterElemsInView[i]);
                    }}>
                        <img src={item.image} className="projectGalleryImage" alt="" />
                        <div className={afterElemsInView[i] === afterIndex ? "projectGalleryImageLine" : "dn"}></div>
                        <div className="projectsGalleryImageHover"></div>
                    </div>
                )
            })
        }
    }

    function renderCurrentImage() {
        if (galleryState === "before") {
            return (
                <img src={props.before[beforeIndex].image} alt="image" />
            )
        } else if (galleryState === "after") {
            return (
                <img src={props.after[afterIndex].image} alt="image" />
            )
        } else return ""
    }

    function updateBeforeIndex(i) {
        if (i > props.before.length - 1) return;
        if (i < 0) return;
        let copy = [...beforeElemsInView];

        if (i > beforeIndex && i > beforeElemsInView[2]) {
            copy.shift();
            copy.push(copy[1] + 1);
        }
        else if (i < beforeIndex && copy[0] - 1 >= 0 && i < beforeElemsInView[0]) {
            copy.pop();
            copy.unshift(copy[0] - 1);
        }

        setBeforeElemsInView([...copy]);
        setBeforeIndex(i)
    }

    function updateAfterIndex(i) {
        if (i > props.after.length - 1) return;
        if (i < 0) return;
        let copy = [...afterElemsInView];

        if (i > afterIndex && copy[2] + 1 !== props.after.length) {
            copy.shift();
            copy.push(copy[1] + 1);
        }
        else if (i < afterIndex && copy[0] - 1 >= 0) {
            copy.pop();
            copy.unshift(copy[0] - 1);
        }

        setAfterElemsInView([...copy]);
        setAfterIndex(i)
    }
}


const ProjectExport = ({ data }) => {
    let params = data.mdx.frontmatter
    return (
        <ProjectTemplate title={params.title} text={params.text} before={params.before} after={params.after}></ProjectTemplate>
    )
}
export default ProjectExport

export const pageQuery = graphql`
query ProjectBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      frontmatter {
        title
        text
        date(formatString: "MMMM DD, YYYY")
        before {
            image
        }
        after{
            image
        }
      }
    }
  }
`