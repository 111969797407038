import * as React from "react"

export default function ArrowLeft(props) {
    return (

        <svg width="9" height="17" viewBox="0 0 9 17" fill="none" className="mr15" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.99485 15.7795C8.99581 15.6198 8.96404 15.4615 8.90138 15.3137C8.83872 15.1659 8.74639 15.0314 8.62968 14.918L2.85001 9.3605C2.73199 9.24769 2.63831 9.11349 2.57439 8.96562C2.51046 8.81775 2.47755 8.65915 2.47755 8.49896C2.47755 8.33878 2.51046 8.18018 2.57439 8.03231C2.63831 7.88444 2.73199 7.75023 2.85001 7.63743L8.62968 2.07993C8.86679 1.85144 9 1.54153 9 1.21839C9 0.895256 8.86679 0.585353 8.62968 0.35686C8.39257 0.128367 8.07098 3.52794e-07 7.73566 3.38137e-07C7.56963 3.30879e-07 7.40522 0.0315154 7.25182 0.0927454C7.09842 0.153975 6.95904 0.243721 6.84164 0.35686L1.07456 5.9265C0.385068 6.61808 -3.29581e-07 7.53992 -3.71502e-07 8.49896C-4.13423e-07 9.45801 0.385067 10.3799 1.07456 11.0714L6.84164 16.6411C6.95869 16.7548 7.09796 16.8451 7.25141 16.9067C7.40485 16.9683 7.56943 17 7.73566 17C7.90189 17 8.06647 16.9683 8.21991 16.9067C8.37336 16.8451 8.51263 16.7548 8.62968 16.6411C8.74639 16.5277 8.83872 16.3932 8.90138 16.2454C8.96404 16.0975 8.99581 15.9392 8.99485 15.7795Z" fill={props.color} />
        </svg>

    )
}